<template>
  <!-- begin:: Footer -->
  <div class="footer bg-white py-4 d-flex flex-lg-column" id="kt_footer">
    <!--begin::Container-->
    <div
      class="d-flex flex-column flex-md-row align-items-center justify-content-between"
      v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <!--begin::Copyright-->
      <div class="text-dark order-2 order-md-1">
        <span class="text-muted font-weight-bold mr-2">2020©</span>
        <a
          :href="CUS_URL"
          target="_blank"
          class="text-dark-75 text-hover-primary"
          >{{ WEBSITE_NAME }}</a
        >
        <template v-if="!HIDE_FOOTER">
          <span class="text-muted font-weight-bold mx-2">Powered by</span>
          <a
            href="https://www.facebook.com/profile.php?id=100063602424547"
            target="_blank"
            class="text-dark-75 text-hover-primary"
          >
            FIFFLE Company
          </a>
        </template>
      </div>
      <!--end::Copyright-->
      <!--begin::Nav-->
      <div class="nav nav-dark order-1 order-md-2" />
      <!--end::Nav-->
    </div>
    <!--end::Container-->
  </div>
  <!-- end:: Footer -->
</template>

<script>
import { mapGetters } from "vuex";
import { WEBSITE_NAME, CUS_URL, HIDE_FOOTER } from "@/config";

export default {
  name: "KTFooter",
  computed: {
    ...mapGetters(["layoutConfig"]),

    /**
     * Check if footer container is fluid
     */
    widthFluid() {
      return this.layoutConfig("footer.width") === "fluid";
    }
  },
  data() {
    return {
      CUS_URL,
      WEBSITE_NAME,
      HIDE_FOOTER
    };
  }
};
</script>
